import React from 'react'

import {
  FormGroup,
  Row,
  Col
} from "reactstrap"

function MatriculateData (props) {

  const { matriculate } = props

  return (
    <>
      <div className="pl-lg-4">
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-first-name"
              >
                Nombre
              </label>
              <p>{matriculate.nombre || '-'}</p>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-first-name"
              >
                Apellido
              </label>
              <p>{matriculate.apellido || '-'}</p>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-first-name"
              >
                Matricula
              </label>
              <p>{matriculate.matricula || '-'}</p>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-first-name"
              >
                Delegacion
              </label>
              <p>{`${matriculate.delegacion ? `Delegacion ${matriculate.delegacion}` : '-'}`}</p>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-first-name"
              >
                CUIT
              </label>
              <p>{matriculate.cuit || '-'}</p>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-first-name"
              >
                Categoría
              </label>
              <p>{matriculate.categoria || '-'}</p>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default MatriculateData