import React, { useState, useEffect, useRef } from 'react'
import Viewer from 'react-viewer'
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col, Label, InputGroup } from 'reactstrap'
import { Link, useParams } from 'react-router-dom'
import { ApiService, attachs as attachNames } from 'services'
import MatriculateData from 'components/Requests/MatriculateData'
import { useAlertPlus } from 'hooks'
import config from 'config.js'
import Comments from './Comments'
import Logs from 'components/Logs'
import { getUTCOffset } from 'helpers/getUTCOffset'
import AlertModal from 'components/AlertModal'
import './Edit.scss'
import moment from 'moment'
import { useAlertError } from 'hooks'
import AlertError from 'components/AlertError'
import DatePicker, { registerLocale } from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import es from 'date-fns/locale/es'
import { useUser } from 'context'

registerLocale('es', es)

function Editor() {
  const { id } = useParams()

  const user = useUser();

  const [ matriculate, setMatriculate ] = useState({})
  const [ observations, setObservations ] = useState([])

  const [ gallery, setGallery ] = useState([])
  const galleryContainer = useRef(null);

  const [ alertShow, alertMessage, alertCallback, alert ] = useAlertPlus('')
  const [ showError, messageError, detailsError, callbackError, alertError ] = useAlertError()

  const [ isLoading, setLoading ] = useState(false)

  const [ request, setRequest ] = useState({})
  const [ updateData, setUpdateData ] = useState({})

  const [ selectedSessions, setSelectedSessions ] = useState(new Set())
  const [ sessionsMoveTarget, setSessionsMoveTarget ] = useState('')
  const [ sessionsMoveStatus, setSessionsMoveStatus ] = useState({
    status: 'idle'
  })

  useEffect(() => {
    if (!id) {
      return
    }

    setLoading(true)
    ApiService.getOne('requests', id)
    .then(res => {

      res.sessionDates = res.sessionDates.map(session => ({
        ...session,
        date: new Date(`${ session.date } ${ getUTCOffset() }`)
      }))

      if (res.authorization_date) {
        res.authorization_date = new Date(`${ res.authorization_date } ${ getUTCOffset() }`)
      }

      if (res.fecha_prescripcion_medica) {
        res.fecha_prescripcion_medica = new Date(`${ res.fecha_prescripcion_medica } ${ getUTCOffset() }`)
      }

      setRequest(res)
      const { attachs, matriculate, observations } = res

      if (attachs) {
        setGallery(
          attachs.map(({ url, tipo, tipo_archivo }) => {
            const parts = url.split('/')
            parts[parts.length - 1] = encodeURIComponent(parts[parts.length - 1])
            url = parts.join('/')

            return {
              src: tipo_archivo !== 'pdf' ? `${config.baseUrl}/${ url }` : require('../../assets/img/pdficon.png'),
              downloadUrl: `${config.baseUrl}/${ url }`,
              alt: attachNames[tipo]
            }
          })
        )
      }

      if (matriculate) {
        setMatriculate(matriculate)
      }

      if (observations) {
        setObservations(observations)
      }
    })
    .catch(err => {
      console.log(err)
    })
    .finally(() => {
      setLoading(false)
    })
  }, [id])

  const handleInputChange = (event) => {
    const { target } = event

    const name = target.name

    let value;
    switch (target.type) {
      case 'checkbox':
        value = target.checked
        break;
      case 'datepicker':
        value = target.value.getFullYear() + '-'
        value += (target.value.getMonth() + 1).toString().padStart(2, '0') + '-'
        value += target.value.getDate().toString().padStart(2, '0')
        break;
      default:
        value = target.value
    }

    setUpdateData({
      ...updateData,
      [name]: value
    })

    setRequest({
      ...request,
      [name]: value
    })
  }

  const handleSessionsSelection = (checked, id) => {
    const newSelection = new Set(selectedSessions)

    if (checked && !newSelection.has(id)) {
      newSelection.add(id)
    } else if (!checked && newSelection.has(id)) {
      newSelection.delete(id)
    } else {
      return
    }

    setSelectedSessions(newSelection)
  }

  const handleSessionsMove = async () => {
    try {
      setLoading(true)

      if (selectedSessions.size === 0) {
        setSessionsMoveStatus({
          status: 'error',
          message: 'No se seleccionaron sesiones para mover'
        })
        return
      }

      const response = await fetch(`${config.baseUrl}/requests/move_sessions`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          source_id: id,
          target_id: sessionsMoveTarget,
          sessions_id: Array.from(selectedSessions)
        })
      })

      const body = await response.json()

      if (body.status !== 'ok') {
        if (body.message && body.error) {
          alertError(body.message, body.error)
          return
        }

        if (body.message) {
          setSessionsMoveStatus(body)
          return
        }

        alertError('Error desconocido intentando realizar la operación', response)
        return
      }

      if (body.data) {
        setRequest(body.data)
      } else {
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      alertError(err.message)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async () => {
    try {
      setLoading(true)

      const response = await fetch(`${config.baseUrl}/requests/${ id }/admin_delete`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        }
      })

      if (response.status === 401 || response.status === 403) {
        alertError('No se tienen los permisos necesarios para realizar la operación')
        return
      }

      const body = await response.json()

      if (body.status !== 'ok') {
        if (body.message && body.error) {
          alertError(body.message, body.error)
          return
        }

        if (body.message) {
          setSessionsMoveStatus(body)
          return
        }

        alertError('Error desconocido intentando realizar la operación', response)
        return
      }

      window.location = '/admin/solicitudes'
    } catch (err) {
      console.log(err)
      alertError(err.message)
    } finally {
      setLoading(false)
    }
  }

  const handleSave = async () => {
    try {
      setLoading(true)

      const query = await fetch(`${config.baseUrl}/requests/admin/${ id }`, {
        method: 'PUT',
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updateData)
      })

      const response = await query.json()

      if (!response.status || response.status !== 'ok') {
        alert(`Se produjo un error al intentar actualizar la solicitud: ${ JSON.stringify(response) }`)
        return
      }

      alert(`Solicitud actualizada correctamente`)
      setUpdateData({})
    } catch (err) {
      console.log(err)
      alert(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Solicitud #{id}</h3>
                </Col>
                <Col className="text-right" xs="4">
                  <Link to='/admin/solicitudes'>
                    <Button
                      color="primary"
                      size="md"
                    >
                      Volver
                    </Button>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col sm={6}>
                    <h6 className="heading-small text-muted mb-4">Datos del matriculado</h6>
                    <MatriculateData matriculate={matriculate} />
                    <hr className="my-4" />

                    <h6 className="heading-small text-muted mb-4">Datos del beneficiario</h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col sm={6}>
                          <FormGroup>
                            <label className='form-control-label'>Nombre</label>
                            <p>{ request?.affiliate?.nombre_apellido || '-' }</p>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <label className='form-control-label'>Edad</label>
                            <p>{ request?.affiliate?.edad || '-' }</p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <FormGroup>
                            <label className='form-control-label'>N° de Beneficiario</label>
                            <p>{ request?.affiliate?.numero_afiliado || '-' }</p>
                            {
                              request.ooss_id === 36 && request?.affiliate?.numero_afiliado.length > 10
                              ? <small className="form-text text-red">El número de afiliado excede el máximo permitido por la OOSS</small>
                              : null
                            }
                          </FormGroup>
                        </Col>
                        {
                          request?.sessionDates?.length > 0 && request.sessionDates[0].plan
                            ? <Col sm={6}>
                                <label className='form-control-label'>Plan</label>
                                <p>{ request.sessionDates[0].plan }</p>
                              </Col>
                            : null
                        }
                      </Row>
                    </div>
                    <hr className="my-4" />

                    <h6 className="heading-small text-muted mb-4">Información de la prestación</h6>
                    <div className="pl-lg-4">
                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-first-name">Obra social</label>
                          <p>{request.ooss ? request.ooss.codigo : '-'}</p>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-first-name">Tipo de práctica</label>
                          <p>{request.tipo_practica || '-'}</p>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-first-name">Práctica adicional</label>
                          <p>{request.practica_adicional || '-'}</p>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-first-name">
                            Prestación es de discapacidad
                          </label>
                          <p>{request.prestacion_discapacidad ? 'Sí' : 'No'}</p>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <label className="form-control-label">Prestación es para refacturación</label>
                          <p>{request.es_refacturacion ? 'Sí' : 'No'}</p>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-first-name">Cantidad de sesiones</label>
                          <p>{
                            request.sessionDates && Array.isArray(request.sessionDates)
                              ? Array.from(request.sessionDates).length
                              : request.cantidad_sesiones
                          }</p>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6} className={ request?.numero_aprobacion ? 'd-block' : 'd-none' }>
                        <label className="form-control-label">
                          { request?.ooss_id == 66 && request?.aprobacion_previa ? 'N° Formulario 4' : 'Número de Aprobación' }
                        </label>
                        <p>
                          { request.numero_aprobacion }
                          {
                            request.ooss_id === 36 && request?.numero_aprobacion.length > 10
                            ? <small className="form-text text-red">
                                El número de aprobación excede el máximo permitido por la OOSS
                              </small>
                            : null
                          }
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label className="form-control-label">Fechas de sesiones</label>
                          <div className='sessions'>
                            <ul>
                              {
                                request.sessionDates && Array.isArray(request.sessionDates)
                                  ? request.sessionDates.sort((a, b) => a.date - b.date).map((session, i) => (
                                    <li key={i}>
                                        <Input
                                          type="checkbox"
                                          name="sessionsSelected"
                                          value={ session.id }
                                          onChange={ event => handleSessionsSelection(event.target.checked, session.id) }
                                        />
                                        Sesión { i + 1}:&nbsp;
                                        { moment(session.date).format('DD/MM/YYYY') }&nbsp;
                                        { session.approvalNumber ? ' - Autorización n° ' + session.approvalNumber : ''}
                                        { session.preAuth
                                          ? request.ooss_id === 66
                                            ? ' - Formulario 4: ' + session.preAuth
                                            : ' - Preautorización: ' + session.preAuth
                                          : ''
                                        }
                                    </li>
                                  ))
                                  : '-'
                              }
                            </ul>
                            <div>
                            <InputGroup size="sm">
                              <Input
                                type="text"
                                disabled={ ![1, 2, 3, 4].includes(request.estado_id) || selectedSessions.size === 0 }
                                placeholder="# de Solicitud"
                                value={ sessionsMoveTarget }
                                onChange={ event => setSessionsMoveTarget(event.target.value) }
                              />
                              <Button
                                color="warning"
                                disabled={ ![1, 2, 3, 4].includes(request.estado_id) || selectedSessions.size === 0 }
                                size="sm"
                                outline={ true }
                                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                onClick={ handleSessionsMove }
                              >
                                Mover sesiones seleccionadas
                              </Button>
                            </InputGroup>
                            {
                              sessionsMoveStatus.status === 'error'
                              ? <small className="form-text text-red">{ sessionsMoveStatus.message }</small>
                              : null
                            }
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className={ request.exceptional ? 'd-block' : 'd-none' }>
                      <Col>
                        <FormGroup>
                          <label className="form-control-label">Excepcionalidad</label>
                          <p>{ request.exceptional_text }</p>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />

                  <h6 className="heading-small text-muted mb-4">Otros datos</h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label className="form-control-label">Fecha de prescripción médica</label>
                      {
                        request.fecha_prescripcion_medica
                          ? <DatePicker
                              className="form-control"
                              locale='es'
                              selected={ new Date(`${ request.fecha_prescripcion_medica }${ getUTCOffset() }`) }
                              onChange={ value => handleInputChange({ target: { type: 'datepicker', name: 'fecha_prescripcion_medica', value } }) }
                              dateFormat="dd/MM/yyyy"
                            />
                          : <DatePicker
                              className="form-control"
                              locale='es'
                              onChange={ value => handleInputChange({ target: { type: 'datepicker', name: 'fecha_prescripcion_medica', value } }) }
                              dateFormat="dd/MM/yyyy"
                            />
                      }
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>N° Mat. Profesional solicitante</label>
                      <p>{request.matricula_profesional_solicitante || '-'}</p>
                    </FormGroup>
                    <FormGroup>
                      <Label className='form-control-label' for="diagnostico">Diagnóstico</Label>
                      <Input
                        id="diagnostico"
                        name="diagnostico"
                        type="textarea"
                        value={ request?.diagnostico ?? '' }
                        onChange={ handleInputChange }
                        disabled={ isLoading }
                        required
                      />
                    </FormGroup>
                  </div>
                  </Col>
                  <Col sm={6} >
                    <div className="sticky-top">
                      <label className="form-control-label">Adjuntos</label>
                      <div id="gallery-container" style={{ height: '80vh' }} ref={ galleryContainer }>
                        {
                          Array.isArray(gallery) && gallery.length && galleryContainer.current
                            ? <Viewer
                                container={ galleryContainer.current }
                                noClose={ true }
                                visible={ true }
                                disableMouseZoom={ true }
                                downloadable={ true }
                                disableKeyboardSupport={ true }
                                zoomSpeed={ 0.5 }
                                downloadInNewWindow= { true }
                                noImgDetails={ true }
                                scalable={ false }
                                showTotal={ false }
                                defaultScale={ 1.25 }
                                defaultImg={{ src: require('../../assets/img/404.png') }}
                                images={ gallery }
                              />
                            : null
                        }
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className={!observations.length ? 'hidden' : '' }>
                  <Col>
                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">Observaciones</h6>
                    <div className="pl-lg-4 mb-4">
                      {
                        observations.map(obs =>
                          <p className="mt-2" key={ obs.id }>
                            {
                              obs.source === 'auditor'
                                ? <i className="fas fa-angle-double-right"></i>
                                : <i className="fas fa-angle-double-left"></i>
                            }
                            &nbsp;
                            { obs.text.split(/\r?\n/).map(line => <>{ line }<br /></>) }
                            &nbsp;
                            <small>{ new Date(obs.createdAt).toLocaleString('es') }</small>
                          </p>
                        )
                      }
                    </div>
                  </Col>
                </Row>
                <Comments requestId={ id } />
                <Logs resource="request" id={ id } open={ true } />
                <Row>
                  <Col className="text-center">
                    <Button
                      type="button"
                      color="warning"
                      className="btn-round mr-4"
                      onClick={ handleDelete }
                      disabled={ isLoading || ![1, 2, 3, 4].includes(request.estado_id) || !!request.sessionDates.length }
                    >
                      Borrar
                    </Button>

                    <Button
                      type="button"
                      color="primary"
                      className="btn-round mr-4"
                      onClick={ handleSave }
                      disabled={ isLoading }
                    >
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          </Col>
        </Row>
      </Container>

      <AlertModal isOpen={ alertShow } message={ alertMessage } onClose={ alertCallback } />
      <AlertError isOpen={ showError } message={ messageError } details={ detailsError } onClose={ callbackError } />
    </>
  )
}

export default Editor
