import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { ApiService } from 'services'
import { Row, Col } from "reactstrap"

const Logs = (props) => {
  const { resource, id, open = false } = props

  const [ logs, setLogs ] = useState([])
  const [ collapsed, setCollapsed ] = useState(!open)

  useEffect(() => {
    ApiService.getAll('logs', {
      resource,
      id
    })
    .then(res => {
      if (res.status !== 'ok') {
        setLogs([])
        console.log(res)
        return
      }
  
      setLogs(res.payload)
    })
    .catch(err => console.log)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Row>
        <Col sm={12}>
          <hr className="my-4" />
        </Col>
      </Row>
      <Row style={{ cursor: 'pointer' }} onClick={ () => setCollapsed(!collapsed) }>
        <Col sm={9}>
          <h6 className="heading-small text-muted mb-4">Movimientos</h6>
        </Col>
        <Col sm={3} className="text-right">
          <i className={`fas ${ collapsed ? 'fa-angle-down' : 'fa-angle-up' } `}></i>
        </Col>
      </Row>
      <Row style={{ transition: 'all 2s' }} className={ collapsed ? 'd-none' : 'd-block' }>
        <Col sm={12}>
          <ul style={{ listStyle: 'none', marginLeft: 0 }} className="pl-lg-4">
            {
              logs.map((log, idx) => {
                let item = ''
                switch (resource) {
                  case 'request':
                    if (log.action === 'CREATE') {
                      item = `Solicitud creada`
                    } else if (log.action === 'DELETE') {
                      item = `Solicitud borrada`
                    } else if (log.action === 'MOVE_SESSIONS') {
                      if (log.data.sessions_id.length === 1) {
                        item = `Movida 1 sesión `
                      } else {
                        item = `Movidas ${ log.data.sessions_id.length } sesiones `
                      }
                      if (log.data.source_id === parseInt(id)) {
                        item += `a la solicitud #${ log.data.target_id }`
                      } else if (log.data.target_id === parseInt(id)) {
                        item += `desde la solicitud #${ log.data.source_id }`
                      }
                    } else if (log.action === 'UPDATE') {
                      item += 'Modificación manual de la solicitud';
                      if (log.data.data && Object.keys(log.data.data).length > 0) {
                        item += `. Campos: ${ Object.keys(log.data.data).join(', ') }`;
                      }
                    } else { // Cambios de estado
                      const target = Number.isInteger(log.data.new_state) ? log.data.new_state : parseInt(log.data.new_state)
                      switch (target) {
                        case 1: item = 'Borrador guardado'; break
                        case 2: item = log.data.old_state === 1 ? 'Solicitud enviada' : 'Solicitud reenviada'; break
                        case 3: item = 'Solicitud aprobada'; break
                        case 4: item = 'Solicitud observada'; break
                        case 5: item = 'Solicitud integrada'; break
                        case 6: item = 'Solicitado envío de factura'; break
                        case 7: item = 'Factura aprobada'; break
                        case 8: item = 'Presentada'; break
                        case 9: item = 'Solicitud anulada'; break
                        default: item = ''
                      }
                    }
                  break
                  case 'bso':
                    if (log.action === 'CREATE') {
                      item = `Solicitud de facturación creada`
                    } else if (log.action === 'DELETE') {
                      item = `Solicitud de facturación borrada`
                    } else { // Cambios de estado
                      switch (log.data.new_state) {
                        case 'Presentado': item = log.data.old_state === 'Pendiente' ? 'Facturación enviada' : 'Facturación reenviada'; break
                        case 'Observado': item = 'Facturación observada'; break
                        case 'Aprobado': item = 'Facturación aprobada'; break
                        default: item = ''
                      }
                    }
                    break
                  default: item = ''
                }

                return (
                  <li key={`log_${ idx }`}>
                    <strong>{ moment(log.time).format('DD/MM/YY HH:mm')}</strong>
                    <span> &nbsp; { item }</span>
                    <small className="font-italic text-muted ml-2">{ log.user }</small>
                  </li>
                )
              })
            }
          </ul>
        </Col>
      </Row>
    </>
  )
}

export default Logs;