import React, { useState } from "react"
import { Card, CardHeader, Row, Col, Input, FormGroup } from "reactstrap"
import { delegations } from "services"

function Filters (props) {

  const { onFilter, fields } = props
  const [showFilters, setShowFilters] = useState(false)

  const getSelectByField = (field) => {
    if (field === 'delegation') {
      return (
        Object
          .entries(delegations)
          .map(([key, value]) => (
            <option key={key} value={key}>{value}</option>)
          )
      )
    }
    const index = fields.findIndex(i => i.field === field)
    if (index !== -1 && fields[index].selectOptions) {
      return (
        Object
          .entries(fields[index].selectOptions)
          .map(([key, value]) => (
            <option key={key} value={key}>{value}</option>)
          )
      )
    }
    return null
  }
  
  const setFilters = () => {
    return fields.map(({ name, field, type, defaultKey }) => (
      <Col key={field} md='4'>
        <FormGroup>
          <label>{name}</label>
          <Input
            // className='form-control-alternative'
            type={type}
            defaultValue={type === 'select' ? defaultKey || 'any' : ''}
            onChange={(e) => filter(field, e.target.value) }
          >
            {type === 'select' ? <>
              <option key='any' value='any'>Todas</option>
              {
                getSelectByField(field)
              }
            </> : null}
          </Input>
        </FormGroup>
      </Col>
    ))
  }

  const filter = (key, value) => {
    if (onFilter) {
      return onFilter({ key, value })
    }
  }

  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  return (
    <>
      <Card className='shadow mb-3'>
        <CardHeader className='border-0'>
          <Row className='align-items-center'>
            <Col>
              <h3 className='clickable' onClick={ () => toggleFilters()}>
                Filtros
              </h3>
              <div className={`table-filters ${showFilters || 'hidden'}`}>
                <Row>
                  { setFilters() }
                </Row>
              </div>
            </Col>
          </Row>
        </CardHeader>
      </Card>
    </>
  )
}

export default Filters
