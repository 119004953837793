import React, { useEffect, useRef, useState } from 'react';
import {
  Card,
  CardFooter,
  Container,
  Row,
  CardBody,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Label,
  Col,
  Table,
  Modal,
  ModalBody,
  Spinner } from 'reactstrap';
import { useAlertPlus } from 'hooks';
import AlertModal from 'components/AlertModal';
import config from 'config';
import { Exchange, ExchangesListQuery, Insurer, ListResponse } from '@cokiba/types';
import ApiService, { getQueryString } from 'services/ApiService';
import { decodeEntities } from 'utils/htmlentitites';
import moment from 'moment';
import Pagination from 'components/Pagination';

let abortController = new AbortController();

export default function Reports() {
  const [alertShow, alertMessage, alertCallback, alert] = useAlertPlus('');

  const [loading, setLoading] = useState(true);

  const [insurers, setInsurers] = useState<Insurer[]>();

  const [detalleRemitoNumero, setDetalleRemitoNumero] = useState('');

  const exchangeRef = useRef<HTMLHeadingElement>(null);
  const [exchangeMessages, setExchangeMessages] = useState<Exchange[]>([]);
  const [exchangeCount, setExchangeCount] = useState(0);
  const [exchangeOptions, setExchangeOptions] = useState<ExchangesListQuery>({});
  const [exchangeDetailsOpen, setExchangeDetailsOpen] = useState(false);
  const [exchangeDetails, setExchangeDetails] = useState('');

  const generateDetalleRemito = async () => {
    const token = localStorage.getItem('accessToken');

    if (!token) {
      return;
    }

    fetch(`${ config.baseUrl }/reports/detalle-remito/${ detalleRemitoNumero }`, {
      method: 'GET',
      headers: {
        Authorization: token,
      },
    })
      .then( res => {
        switch (res.status) {
          case 200:
            return res.blob();
          case 403:
            alert('No tiene los permisos necesarios para realizar la operación');
            break;
          case 404:
            alert('No se pudo encontrar el remito indicado');
            break;
          case 501:
            alert('El remito indicado no pertenece a una obra social implementada');
            break;
          default:
            alert('Ocurrió un error impreviso cuando se intentó realizar la operación');
            console.error(res);
        }
      })
      .then( blob => {
        if (!blob) {
          return;
        }

        const file = window.URL.createObjectURL(blob);
        window.location.assign(file);
      })
      .catch(err => {
        console.error(err);
        alert('Ocurrió un error de conexión cuando se intentó realizar la operación');
      });
  };

  const fetchExchangeMessages = (newOptions: ExchangesListQuery = {}) => {
    const token = localStorage.getItem('accessToken');

    if (!token) {
      return;
    }

    setLoading(true);

    abortController.abort();
    abortController = new AbortController();

    const options = Object.assign({}, exchangeOptions, newOptions);

    const queryString = getQueryString(options);

    fetch(`${ config.baseUrl }/reports/exchanges${ queryString }`, {
      method: 'GET',
      headers: {
        Authorization: token,
      },
      signal: abortController.signal,
    })
      .then( res => {
        switch (res.status) {
          case 200:
            setExchangeOptions(options);
            return res.json() as Promise<ListResponse<Exchange>>;
          case 403:
            alert('No tiene los permisos necesarios para realizar la operación');
            break;
          default:
            alert('Ocurrió un error impreviso cuando se intentó realizar la operación');
            console.error(res);
        }
      })
      .then( data => {
        if (!data) {
          return;
        }

        setExchangeMessages(data.payload.rows);
        setExchangeCount(data.payload.count);
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          return;
        }

        console.error(err);
        alert('Ocurrió un error de conexión cuando se intentó realizar la operación');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeFilterExchange = (key: string, value: unknown) => {
    fetchExchangeMessages({
      [key]: value && value != 'any' ? value : undefined,
    });
  };

  const changePageExchange = async (page: number) => {
    await fetchExchangeMessages({ page });
    exchangeRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const showDetails = (exchange: Exchange, type: 'request' | 'response') => {
    let details: string;
    if (type === 'request' && exchange.request_content) {
      details = exchange.request_content;
    } else if (type === 'response' && exchange.response_content) {
      details = exchange.response_content;
    } else {
      details = '-';
    }

    // Normalizamos los saltos de línea
    details = details.replace(/\n?\r+/g, '\n');

    // Reemplazamos los saltos de línea que estén codificados
    details = details.replace(/(?:\\[rn])+/g, '\n');

    if (exchange.insurer_id == 36 && type == 'request' && exchange.request_content) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(details, 'application/xml');
      const data = doc.querySelector('pSolicitud');
      if (data) {
        details = decodeEntities(data.innerHTML);
      }
    } else if (exchange.insurer_id == 36 && type == 'response' && exchange.response_content) {
      details = JSON.stringify(JSON.parse(details), undefined, 4);
    }

    setExchangeDetails(details);
    setExchangeDetailsOpen(true);
  };

  useEffect(() => {
    fetchExchangeMessages();
    ApiService.getAll('insurers', { onlyActive: 1 })
      .then(res => {
        if (!res.status || res.status !== 'ok') {
          return;
        }

        setInsurers(res.payload);
      })
      .catch(err => {
        alert('Ha ocurrido un error al obtener el listado de Obras Sociales, ' +
          'no se podrá filtrar por obra social.');
        console.error(err);
      });
  }, []);

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow" style={{ marginBottom: '1rem' }}>
              <CardBody>
                <Row style={{ minHeight: '3rem' }}>
                  <Col sm={ 6 } style={{ display: 'flex', alignItems: 'center' }}>
                    <h3 ref={ exchangeRef } style={{ margin: 0 }}>Consulta Mensajes con OOSS</h3>
                    { loading && <Spinner size="sm" style={{ marginLeft: '1rem' }} /> }
                  </Col>
                  <Col sm={ 6 }>
                    <Input
                      type="select"
                      onChange={ event => changeFilterExchange('insurer_id', event.target.value) }
                      defaultValue="any"
                    >
                      <option value="any">Todas las OOSS</option>
                      {
                        insurers && Array.isArray(insurers)
                          ? insurers.map(insurer => (
                            <option key={'insurer_' + insurer.id} value={ insurer.id }>
                              { insurer.codigo }
                            </option>
                          ))
                          : null
                      }
                    </Input>
                  </Col>
                </Row>
                <Table style={{ marginBottom: '1rem' }}>
                  <thead>
                    <tr>
                      <th>
                        <Input
                          bsSize="sm"
                          placeholder="Matriculado"
                          style={{ maxWidth: '100px' }}
                          onChange={ event => changeFilterExchange('matriculate_id', event.target.value) }
                        />
                      </th>
                      <th>
                        <Input
                          bsSize="sm"
                          placeholder="Afiliado"
                          style={{ maxWidth: '100px' }}
                          onChange={ event => changeFilterExchange('recipient_id', event.target.value) }
                        />
                      </th>
                      <th>
                        <Input
                          bsSize="sm"
                          type="select"
                          onChange={ event => changeFilterExchange('operation', event.target.value) }
                          defaultValue="any"
                        >
                          <option value="any">Operación</option>
                          <option value="authorize">Autorización</option>
                          <option value="annul">Anulación</option>
                          <option value="affiliate">Validación</option>
                        </Input>
                      </th>
                      <th>Fecha Solicitud</th>
                      <th>Solicitud</th>
                      <th>Fecha Respuesta</th>
                      <th>Respuesta</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      exchangeMessages.map(exchange => (
                        <tr key={ exchange.id }>
                          <td style={{ verticalAlign: 'middle' }}>
                            { exchange.matriculate_id ?? '-' }
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            { exchange.recipient_id ?? '-' }
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            { exchange.operation ?? '-' }
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            {
                              exchange.request_date && moment(exchange.request_date).isValid()
                                ? moment(exchange.request_date).format('DD/MM/YYYY HH:mm:ss')
                                : '-'
                            }
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            <Button
                              size="sm"
                              color="link"
                              onClick={ () => showDetails(exchange, 'request') }
                              disabled={ loading }
                            >
                              Solicitud
                            </Button>
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            {
                              exchange.response_date && moment(exchange.response_date).isValid()
                                ? moment(exchange.response_date).format('DD/MM/YYYY HH:mm:ss')
                                : '-'
                            }
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            <Button
                              size="sm"
                              color="link"
                              onClick={ () => showDetails(exchange, 'response') }
                              disabled={ loading }
                            >
                              Respuesta
                            </Button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                <Pagination
                  page={ exchangeOptions.page ? exchangeOptions.page : 1 }
                  count={ exchangeCount }
                  onChangePage={ changePageExchange }
                  limit={ 10 }
                />
              </CardBody>
            </Card>
            <Card className="shadow">
              <CardBody>
                <h3>Detalle Remito</h3>
                <small>Genera un archivo Excel donde se detalla de cada sesión rendida la fecha de prescripción,
                  fecha de sesión, el número de afiliado, el nombre de afiliado, el número de matriculado, nombre
                  de matriculado, código de prestacion y número de autorización. Únicamente están soportados <strong>
                  SMG</strong> y <strong>OSPe</strong>
                </small>
                <FormGroup row>
                  <Col sm={2}>
                    <Label for="input-last-name">N° Remito</Label>
                  </Col>
                  <Col sm={4}>
                    <InputGroup size="sm">
                      <Input
                        type="text"
                        placeholder="# de Remito"
                        value={ detalleRemitoNumero }
                        onChange={ event => setDetalleRemitoNumero(event.target.value) }
                      />
                      <Button
                        color="primary"
                        size="sm"
                        outline={ true }
                        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                        onClick={ generateDetalleRemito }
                      >
                        Generar Detalle
                      </Button>
                    </InputGroup>
                  </Col>
                </FormGroup>
              </CardBody>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </Col>
        </Row>
        <AlertModal isOpen={ alertShow } message={ alertMessage } onClose={ alertCallback } />
      </Container>
      <Modal isOpen={ exchangeDetailsOpen } size="lg" toggle={ () => setExchangeDetailsOpen(!exchangeDetailsOpen) }>
        <ModalBody>
          <pre>
            { exchangeDetails }
          </pre>
        </ModalBody>
      </Modal>
    </>
  );
}
