// cambiar a custom hook! yo no sabia mucho react
// asi que trate a las llamadas a la api como servicios
// como se hace en Vue.js 
import config from 'config'
import { responses as responseHelper } from 'helpers'

export const responses = (code) => {
  switch (code) {
    case '401':
      return '401 Unauthorized'
    case '404':
      return '404 Not Found'
    default:
      return undefined
  }
}

const BASE_URL = config.baseUrl

const redirectToLogin = () => window.location = '/auth/login'

const logout = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('userData')
  redirectToLogin()
}

const checkTokenExistence = () => {
  if (!localStorage.getItem('accessToken') || !localStorage.getItem('userData')) {
    redirectToLogin()
  }
}

// example: { key: value, foo: bar } is traduced in ?key=value&foo=bar
export const getQueryString = (options) => {
  let queryString = ''
  if (options !== undefined && typeof options === 'object') {
    queryString += '?'
    const optEntries = Object.entries(options)
    let firstEl = true
    for (let [key, value] of optEntries) {
      if (value == undefined) {
        continue
      }

      if (typeof value === 'object') {
        value = JSON.stringify(value)
      }
      value = encodeURIComponent(value)
      queryString += firstEl ? `${key}=${value}` : `&${key}=${value}`
      firstEl = false
    }
  }
  return queryString
}

export default {
  async signIn (data) {
    const res = await fetch(`${BASE_URL}/auth/login`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })

    return res
  },

  async post (service, data) {
    checkTokenExistence()

    const res = await fetch(`${BASE_URL}/${service}`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })

    if (res.status === 403) logout()
    if (res.status === 401) redirectToLogin()

    if (res.status === 404) {
        return responseHelper.notFound()
      }
      if (res.status === 400) {
        return responseHelper.withError()
      }

    return res.json()
  },

  async postAuth (service, data) {
    const res = await fetch(`${BASE_URL}/auth/${service}`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })

    if (res.status === 403) logout()
    if (res.status === 401) redirectToLogin()

    return res.json()
  },

  async getAll (service, options) {
    checkTokenExistence()

    const queryString = getQueryString(options)
    const res = await fetch(`${BASE_URL}/${service}${queryString}`, {
      headers: {
        Authorization: localStorage.getItem('accessToken')
      }
    })

    if (res.status === 403) logout()
    if (res.status === 401) redirectToLogin()

    return res.json()
  },

  async getOne (service, id, options) {
    checkTokenExistence()

    const queryString = getQueryString(options)
    const res = await fetch(`${BASE_URL}/${service}/${id}${queryString}`, {
      headers: {
        Authorization: localStorage.getItem('accessToken')
      }
    })

    if (res.status === 403) logout()
    if (res.status === 401) redirectToLogin()

    return res.json()
  },

  async delete (service, id) {
    checkTokenExistence()

    const res = await fetch(`${BASE_URL}/${service}/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: localStorage.getItem('accessToken')
      }
    })

    if (res.status === 403) logout()
    if (res.status === 401) redirectToLogin()

    return res.json()
  },

  async update (service, id, data) {
    checkTokenExistence()

    const res = await fetch(`${BASE_URL}/${service}/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })

    if (res.status === 403) logout()
    if (res.status === 401) redirectToLogin()

    return res.json()
  },

  async generateBso (data) {
    checkTokenExistence()

    const res = await fetch(`${BASE_URL}/submission-orders`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })

    if (res.status === 403) logout()
    if (res.status === 401) redirectToLogin()

    if (res.status === 404) {
      return responseHelper.notFound()
    }
    if (res.status === 400) {
      return responseHelper.withError()
    }

    return res.json()
  }
}