import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Button, Table } from 'reactstrap';
import TableLoader from 'components/TableLoader';
import Loader from 'components/Loader';

import './RemitosTable.scss';
import { RemitoRaw } from '@cokiba/types';

interface RemitosTableProps {
  items: RemitoRaw[];
  isLoading: boolean;
  disableGenerators?: boolean;
  onProcessStart: (type: string, deliveryNoteId: number) => void;
  onSendRetry: (deliveryNoteId: number) => void;
  onSendStart: (deliveryNoteId: number) => void;
  onSendStop: (deliveryNoteId: number) => void;
}

interface ButtonFileProps {
  data: RemitoRaw;
  type: string;
}

interface ButtonFileOptions {
  color: string;
  title: string;
  icon: string;
  generate: boolean;
  loader: boolean;
  send: boolean;
  retry: boolean;
  stop: boolean;
}

export default function RemitosTable(props: RemitosTableProps) {
  const { items, isLoading, onProcessStart, onSendRetry, disableGenerators = true, onSendStart, onSendStop } = props;

  const columns = [
    'Número remito',
    'Mes presentación',
    'Obra social',
    'Dígito obra social',
    'Acciones',
  ];

  const ButtonFile = (props: ButtonFileProps) => {
    const { data, type } = props;

    const options: ButtonFileOptions = {
      color: '',
      title: '',
      icon: '',
      generate: false,
      loader: false,
      send: false,
      retry: false,
      stop: false,
    };

    if (type === 'zip' && data.idObraSocial === 36) {
      options['color'] = 'secondary';
      options['title'] = 'Generar ZIP';
      options['icon'] = 'fa-file-archive';
      options['generate'] = !data.zip;
      options['loader'] = !!data.zip && data.zip.status_id < 6;
      options['send'] = false;

      options['retry'] = false;
      if (options['loader'] && data.zip?.updated_at) {
        const lastUpdate = moment(data.zip.updated_at);
        const days = moment().diff(lastUpdate, 'days');

        if (days >= 1) {
          options['retry'] = true;
        }
      }
    } else if (type === 'pdf' && data.idObraSocial !== 57) {
      options['color'] = 'warning';
      options['title'] = 'Generar PDF';
      options['icon'] = 'fa-file-pdf';
      options['generate'] = !data.pdf;
      options['loader'] = !!data.pdf && data.pdf.status_id < 3;
      options['retry'] = false;
      options['send'] = false;

      if (options['loader'] && data.pdf?.updated_at) {
        const lastUpdate = moment(data.pdf.updated_at);
        const days = moment().diff(lastUpdate, 'days');

        if (days >= 1) {
          options['generate'] = true;
        }
      }
    } else if (type === 'send' && data.idObraSocial === 57) {
      options['color'] = 'danger';
      options['title'] = 'Enviar Documentación';
      options['icon'] = 'fa-file-import';
      options['generate'] = false;
      options['loader'] = !!data.send && data.send.status_id < 2;
      options['retry'] = false;
      options['send'] = !data.send;

      if (options['loader'] && data.send?.updated_at) {
        const lastUpdate = moment(data.send.updated_at);
        const days = moment().diff(lastUpdate, 'days');

        if (days >= 1) {
          options['retry'] = true;
        }
      }
    }

    return (
      <>
        {
          options.generate
            ? <Button
                color={ options.color }
                size="sm"
                onClick={ () => onProcessStart(type, data.numeroRemito) }
                disabled={ disableGenerators }
                title={ options.title }
              >
                <i className={`fa ${ options.icon }`}></i>
              </Button>
            : null
        }
        {
          options.retry
            ? <Button
                color="info"
                size="sm"
                onClick={ () => onSendRetry(data.numeroRemito) }
                title="Reintentar Envío"
              >
                <i className="fas fa-redo"></i>
              </Button>
            : null
        }
        {
          options.send
            ? <Button
                color={ options.color }
                size="sm"
                onClick={ () => onSendStart(data.numeroRemito) }
                disabled={ disableGenerators && !options.loader }
                title="Enviar documentación"
              >
                <i className={`fa ${ options.icon }`}></i>
              </Button>
            : null
        }
        {
          options.stop
            ? <Button
                color="warning"
                size="sm"
                onClick={ () => onSendStop(data.numeroRemito) }
                disabled={ disableGenerators && !options.loader }
                title="Detener envío"
              >
                <i className="fa fa-stop"></i>
              </Button>
            : null
        }
        { options.loader && <Loader className={`button ${ type }`} /> }
        {
          options.loader && data.pdf
            ? `${ data.pdf.processed } / ${ data.pdf.total }`
            : null
        }
        {
          options.loader && data.zip
            ? `${ data.zip.processed } / ${ data.zip.total }`
            : null
        }
        {
          options.loader && data.send
            ? `${ data.send.sent } / ${ data.send.total }`
            : null
        }
      </>
    );
  };

  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            {
              columns.map((colName, i) => (
                <th key={ i } scope='col' className='clickable'>{ colName }</th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            !isLoading ?
              items.length ?
                items.map(row => {
                  return (
                    <tr key={`remito_${ row.numeroRemito }`}>
                      <td>{row.numeroRemito}</td>
                      <td>{row.mesPresentacion}</td>
                      <td>{row.obraSocial}</td>
                      <td>{row.digitoObraSocial || '-'}</td>
                      <td>
                        <Link to={`/admin/remitos/${ row.numeroRemito }`} className="btn btn-round btn-primary btn-sm">
                          <i className="fa fa-eye"></i>
                        </Link>
                        <ButtonFile type="zip" data={ row } />
                        <ButtonFile type="pdf" data={ row } />
                        <ButtonFile type="send" data={ row } />
                      </td>
                    </tr>
                  );
                })
                :
              // content empty
                  <tr>
                    <td colSpan={columns.length + 1} className='text-center'>
                      <span>No se encontraron remitos generados.</span>
                    </td>
                  </tr>
              :
            // loading state
                <tr>
                  <td colSpan={columns.length + 1} className='text-center'>
                    <TableLoader />
                  </td>
                </tr>
          }
        </tbody>
      </Table>
    </>
  );
}
