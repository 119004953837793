import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { Button, Card, CardHeader, CardBody, Container, Row, Col, FormGroup, Input } from 'reactstrap'
import { ApiService } from 'services'
import Header from 'components/Headers/Header'
import Loader from 'components/Loader'
import { titleCase } from 'helpers'
import { useConfirm } from "hooks"
import config from 'config'
import ConfirmModal from "components/ConfirmModal"
import Logs from 'components/Logs'

export default function Audit() {
  const [ isLoading, setIsLoading ] = useState(false)
  const [ data, setData ] = useState({})
  
  const [ observation, setObservation ] = useState('')
  const [ isEmpty, setIsEmpty ] = useState(false)
  const [ showObservationForm, setShowObservationForm ] = useState(false)
  const [ confirm, confirmTitle, confirmAction, setConfirm ] = useConfirm(false)

  const approvalButton = useRef()
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    if (!id) return

    setIsLoading(true)

    ApiService.getOne('submission-orders', id)
    .then(res => {
      setData(res)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }, [id])

  const showObservation = () => {
    setShowObservationForm(true)

    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      })
    }, 100)
  }

  const submitObservation = (origin) => {
    if (!observation || observation === '') {
      setIsEmpty(true)
      return
    }

    origin.disabled = true

    setIsEmpty(false)

    const data = {
      status: 'observed',
      observation
    }

    ApiService.update('submission-orders', id, data)
    .then(() => {
      origin.disabled = false
      navigate('/admin/pedidos-presentacion', {
        state: { success: true }
      })
    })
  }

  const cancelObservation = () => {
    setShowObservationForm(false)
    setObservation('')
    setIsEmpty(false)
  }

  const submitApproval = async (confirmed = false) => {
    approvalButton.current.disabled = true

    if (!confirmed) {
      return setConfirm(true, '¿Estás seguro que deseas aceptar esta solicitud?', 'accept')
    }

    const data = {
      status: 'approved'
    }

    ApiService.update('submission-orders', id, data)
    .then(() => {
      navigate('/admin/pedidos-presentacion', {
        state: { success: true }
      })
    })
    .catch(err => {
      approvalButton.current.disabled = false
      console.log(err)
    })
  }

  const onConfirm = (action) => {
    setConfirm(false)
    if (action === 'observe') {
      return submitObservation(true)
    }
    if (action === 'accept') {
      return submitApproval(true)
    }
  }

  return(
    <>
      <Header />
      <Container className="mt--7" fluid>
        <ConfirmModal
          isOpen={confirm}
          title={confirmTitle}
          action={confirmAction}
          onClose={ () => setConfirm(false) }
          onConfirm={ (action) => onConfirm(action) }
        />
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">
                  <span className='mr-2'>Auditar Factura</span>
                </h3>
              </Col>
              <Col className="text-right" xs="4">
                <Link to='/admin/pedidos-presentacion'>
                  <Button color="primary" size="md">
                    Volver
                  </Button>
                </Link>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {
              isLoading
              ? <Loader />
              : <>
                  <Row className='mb-4'>
                    <Col lg='3'>Matriculado</Col>
                    <Col lg='9'>{ data.matriculate ? titleCase(`${data.matriculate.apellido} ${data.matriculate.nombre} (Mat. ${ data.matriculate.matricula })`) : '-' }</Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col lg='3'>Delegación</Col>
                    <Col lg='9'>{ data.matriculate ? data.matriculate.delegacion : '-' }</Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col lg='3'>Obra Social</Col>
                    <Col lg='9'>{ data.ooss ? data.ooss.nombre : '-' }</Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col lg='3'>Periodo</Col>
                    <Col lg='9'>{ data.monthPeriod }/{ data.yearPeriod }</Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col lg='3'>Fecha de solicitud</Col>
                    <Col lg='9'>{ new Date(data.createdAt).toLocaleDateString('es-AR', { dateStyle: 'short' }) }</Col>
                  </Row>
                  {
                    data.attachs && Array.isArray(data.attachs) && data.attachs.length
                    ? data.attachs.map(({ type, url }, idx) =>
                        <Row className="mb-4" key={`attach_${idx}`}>
                          <Col md='3'>{ type === 'factura' ? 'Factura' : 'Nota de Crédito' }</Col>
                          <Col md='9'>
                            <Button color="primary" onClick={ () => window.open(`${config.baseUrl}/${url}`, "_blank") }>Ver adjunto</Button>
                          </Col>
                        </Row>
                      )
                    : null
                  }
                  {
                    data.observations && Array.isArray(data.observations) && data.observations.length
                    ? <Row>
                        <Col lg="12">
                          <hr className="my-4" />
                          <h6 className="heading-small text-muted mb-4">Observaciones</h6>
                          <div className="pl-lg-4 mb-4">
                            {
                              data.observations.map(obs =>
                                <p className='mt-2' key={obs.id}>
                                  <span><strong>{new Date(obs.createdAt).toLocaleString('es')}</strong> - </span> { obs.text }
                                </p>
                              )
                            }
                          </div>
                        </Col>
                      </Row>
                    : null
                  } 
                  <Row className={!showObservationForm ? 'hidden' : '' }>
                    <Col lg="12">
                      <FormGroup>
                        <Input autoComplete='off'
                          className={isEmpty ? `required` : 'form-control-alternative'}
                          type="textarea"
                          rows="4"
                          name="observation"
                          placeholder="Agregar observación"
                          onChange={ event => setObservation(event.target.value) }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className={!showObservationForm ? 'hidden' : '' }>
                    <div className="update ml-auto mr-auto">
                      <Button className="btn-round" color="success" disabled={ false } onClick={ event => submitObservation(event.target) }>
                        Aceptar
                      </Button>
                      <Button className="btn-round" color="error" size="md" onClick={ () => cancelObservation() }>
                        Cancelar
                      </Button>
                    </div>
                  </Row>
                  <Row className={ showObservationForm ? 'hidden' : '' }>
                    <div className="update ml-auto mr-auto">
                      <Button className="btn-round" color="primary" disabled={ false } onClick={ () => showObservation() }>
                        Observar Factura
                      </Button>
                      <Button ref={ approvalButton } className="btn-round" color="success" size="md" onClick={ () => submitApproval() }>
                        Aprobar Factura
                      </Button>
                    </div>
                  </Row>
                  <Logs resource="bso" id={ id } />
                </>
            }
          </CardBody>
        </Card>
      </Container>
    </>
  )
}
