import React, { useState, useEffect } from 'react'
import { Card, CardHeader, CardBody, FormGroup, Form, Container, Row, Col, Button } from 'reactstrap'
import { useParams, useNavigate } from 'react-router-dom'
import { ApiService, attachs as attachNames } from 'services'
import MatriculateData from 'components/Requests/MatriculateData'
import ProvisionInformation from 'components/Requests/ProvisionInformation'
import AttachModal from 'components/AttachModal'
import config from 'config.js'
import Comments from './Comments'
import Logs from 'components/Logs'
import { getUTCOffset } from 'helpers/getUTCOffset'

function Show () {
  const { id } = useParams()

  const navigate = useNavigate()

  const [matriculate, setMatriculate] = useState({})
  const [request, setRequest] = useState({})
  const [attachs, setAttachs] = useState([])
  const [observations, setObservations] = useState([])

  const [currentAttach, setCurrentAttach] = useState(null)
  const [showAttachModal,setShowAttachModal] = useState(false)

  useEffect(() => {
    if (!id) {
      return
    }

    ApiService.getOne('requests', id)
    .then(res => {
      res.sessionDates = res.sessionDates.map(session => ({
        ...session,
        date: new Date(`${ session.date } ${ getUTCOffset() }`)
      }))

      if (res.authorization_date) {
        res.authorization_date = new Date(`${ res.authorization_date } ${ getUTCOffset() }`)
      }

      if (res.fecha_prescripcion_medica) {
        res.fecha_prescripcion_medica = new Date(`${ res.fecha_prescripcion_medica } ${ getUTCOffset() }`)
      }

      setRequest(res)
      const { attachs, matriculate, observations } = res
      if (attachs) {
        setCurrentAttach(0)

        setAttachs(
          attachs.map((({ tipo, tipo_archivo, url }) => {
            const parts = url.split('/')
            parts[parts.length - 1] = encodeURIComponent(parts[parts.length - 1])
            url = parts.join('/')

            return {
              tipo,
              tipo_archivo,
              url
            }
          }))
        )
      }
      if (matriculate) {
        setMatriculate(matriculate)
      }
      if (observations) {
        setObservations(observations)
      }
    })
  }, [id])

  const handleClickImage = () => {
    const attach = attachs[currentAttach]
    if (attach.tipo_archivo === 'pdf') {
      return window.open(`${config.baseUrl}/${attachs[currentAttach].url}`, "_blank")
    }
    setShowAttachModal(true)
  }

  const handleClickThumbnail = (index) => {
    setCurrentAttach(index)
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" />
      {/* Attach modal */}
      <AttachModal
        isOpen={showAttachModal}
        title={attachs.length && attachNames[attachs[currentAttach].tipo]}
        url={attachs.length && `${config.baseUrl}/${attachs[currentAttach].url}`}
        onClose={ () => setShowAttachModal(false)}
      />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Solicitud #{id}</h3>
                </Col>
                <Col className="text-right" xs="4">
                  <Button color="primary" size="md" onClick={ () => navigate(-1) }>
                    Volver
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col sm={6}>
                    <h6 className="heading-small text-muted mb-4">Datos del matriculado</h6>
                    <MatriculateData matriculate={matriculate} />
                    <hr className="my-4" />

                    <h6 className="heading-small text-muted mb-4">Datos del beneficiario</h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col sm={6}>
                          <FormGroup>
                            <label className='form-control-label'>Nombre</label>
                            <p>{ request?.affiliate?.nombre_apellido || '-' }</p>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <label className='form-control-label'>Edad</label>
                            <p>{ request?.affiliate?.edad || '-' }</p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <FormGroup>
                            <label className='form-control-label'>N° de Beneficiario</label>
                            <p>{ request?.affiliate?.numero_afiliado || '-' }</p>
                          </FormGroup>
                        </Col>
                        {
                          request?.sessionDates?.length > 0 && request.sessionDates[0].plan
                            ? <Col sm={6}>
                                <label className='form-control-label'>Plan</label>
                                <p>{ request.sessionDates[0].plan }</p>
                              </Col>
                            : null
                        }
                      </Row>
                    </div>
                    <hr className="my-4" />

                    <h6 className="heading-small text-muted mb-4">Información de la prestación</h6>
                    <ProvisionInformation request={request} />
                    <hr className="my-4" />

                    <h6 className="heading-small text-muted mb-4">Otros datos</h6>
                    <div className="pl-lg-4">
                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <label className='form-control-label'>N° Mat. Profesional solicitante</label>
                          <p>{request.matricula_profesional_solicitante || '-'}</p>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-first-name">
                            Fecha de prescripción médica
                          </label>
                          <p>{request.fecha_prescripcion_medica ? new Date(request.fecha_prescripcion_medica).toLocaleDateString('es') : '-'}</p>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-first-name">
                            Diagnóstico
                          </label>
                          <p>{request.diagnostico || '-'}</p>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Col>
                  <Col sm={6}>
                    <h6 className="heading-small text-muted mb-4">Imágenes</h6>
                    <Row>
                      <Col sm={12}>
                        {
                          attachs.length &&
                          <label>{attachNames[attachs[currentAttach].tipo]}</label>
                        }
                        <div className='request-image'>
                          {
                            attachs.length &&
                            <img
                              className='request-image__main d-block m-auto clickable'
                              alt={`${attachs[currentAttach].tipo}`}
                              title='Click para ver'
                              src={attachs[currentAttach].tipo_archivo === 'pdf' ? require('../../assets/img/pdficon.png') : `${config.baseUrl}/${attachs[currentAttach].url}`} // require('../../assets/img/pdficon.png')
                              onClick={ () => handleClickImage() }
                            />
                          }
                        </div>
                      </Col>
                      <Col md='12' className='row no-gutters mt-4'>
                        <Row className='no-gutters'>
                          {
                            attachs.map(({ url, tipo, tipo_archivo }, i) => {
                              if (i < 7) {
                                return (
                                  <Col
                                    key={i}
                                    className='request-image--thumbnail img-thumbnail clickable'
                                    onClick={ () => handleClickThumbnail(i)}
                                  >
                                    {
                                      <img
                                        className='request-image--thumbnail__img d-block m-auto'
                                        alt={`${tipo}`}
                                        title={`${attachNames[tipo]}`}
                                        src={tipo_archivo === 'pdf' ? require('../../assets/img/pdficon.png') : `${config.baseUrl}/${url}`}
                                      />
                                    }
                                  </Col>
                                )
                              }
                              return null
                            })
                          }
                        </Row>
                      </Col>
                      {attachs[7] && <Col md='12' className='row no-gutters mt-4'>
                        <Row className='no-gutters'>
                          {
                            attachs.map(({ url, tipo, tipo_archivo }, i) => {
                              if (i >= 7 && i < 14) {
                                return (
                                  <Col
                                    key={i}
                                    className='request-image--thumbnail img-thumbnail clickable'
                                    onClick={ () => handleClickThumbnail(i)}
                                  >
                                    {
                                      <img
                                        className='request-image--thumbnail__img d-block m-auto'
                                        alt={`${tipo}`}
                                        title={`${attachNames[tipo]}`}
                                        src={tipo_archivo === 'pdf' ? require('../../assets/img/pdficon.png') : `${config.baseUrl}/${url}`}
                                      />
                                    }
                                  </Col>
                                )
                              }
                              return null
                            })
                          }
                        </Row>
                      </Col>}
                      {attachs[14] && <Col md='12' className='row no-gutters mt-4'>
                        <Row className='no-gutters'>
                          {
                            attachs.map(({ url, tipo, tipo_archivo }, i) => {
                              if (i >= 14) {
                                return (
                                  <Col
                                    key={i}
                                    className='request-image--thumbnail img-thumbnail clickable'
                                    onClick={ () => handleClickThumbnail(i)}
                                  >
                                    {
                                      <img
                                        className='request-image--thumbnail__img d-block m-auto'
                                        alt={`${tipo}`}
                                        title={`${attachNames[tipo]}`}
                                        src={tipo_archivo === 'pdf' ? require('../../assets/img/pdficon.png') : `${config.baseUrl}/${url}`}
                                      />
                                    }
                                  </Col>
                                )
                              }
                              return null
                            })
                          }
                        </Row>
                      </Col>}
                  </Row>
                </Col>
                </Row>
                <Row className={!observations.length ? 'hidden' : '' }>
                  <Col lg="12">
                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">Observaciones</h6>
                    <div className="pl-lg-4 mb-4">
                      {
                        observations.map(obs =>
                          <p className="mt-2" key={ obs.id }>
                            {
                              obs.source === 'auditor'
                                ? <i className="fas fa-angle-double-right"></i>
                                : <i className="fas fa-angle-double-left"></i>
                            }
                            &nbsp;
                            { obs.text.split(/\r?\n/).map(line => <>{ line }<br /></>) }
                            &nbsp;
                            <small>{ new Date(obs.createdAt).toLocaleString('es') }</small>
                          </p>
                        )
                      }
                    </div>
                  </Col>
                </Row>
                <Comments requestId={ id } />
                <Logs resource="request" id={ id } />
              </Form>
            </CardBody>
          </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Show
