import React from 'react'
import CollapsibleCard from '../../components/CollapsibleCard'
import { Col, Row } from 'reactstrap'

function RequestFilters() {
  return (
    <CollapsibleCard title="Filtros">
      <Row>
        <Col md="12">
          
        </Col>
      </Row>
    </CollapsibleCard>
  )
}

export default RequestFilters
