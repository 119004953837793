import React from 'react'
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import ReactPanZoom from '@augustoresende/react-image-pan-zoom-rotate'

function AttachModal (props) {
  const { title, url, onClose, isOpen } = props

  const close = () => {
    if (!onClose) {
      return
    }

    onClose()
  }

  return (
    <Modal
      isOpen={ isOpen }
      size="lg"
      centered={ true }
      scrollable={ true }
    >
      <ModalHeader>{ title }</ModalHeader>
      <ModalBody>
        <ReactPanZoom image={ url } />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={ () => close() }>Cerrar</Button>
      </ModalFooter>
    </Modal>
  )
}

export default AttachModal