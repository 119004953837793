import React, { SetStateAction, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
  Row,
  Col,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import { useForm } from 'hooks';
import { ApiService } from 'services';
import Loader from 'components/Loader';
import DatePicker from 'components/DatePicker';
import { Insurer } from '@cokiba/types';

interface RemitosFormProps {
  title: string;

  data: {
    mesPresentacion: string;
    nroOS: string;
  }
  setData: (data: RemitosFormProps['data']) => void;

  onSubmit: () => void;

  isLoading: boolean;
}

type RemitosFormErrors = Record<keyof RemitosFormProps['data'], boolean>;

function RemitosForm(props: RemitosFormProps) {
  const { title, data, setData, onSubmit, isLoading } = props;

  const [values, setValues] = useState(data);
  const [ooss, setOOSS] = useState<Insurer[]>([]);
  const [errors, setErrors] = useState<RemitosFormErrors>({
    mesPresentacion: false,
    nroOS: false,
  });

  useEffect(() => {
    getOOSS();
  }, []);

  useEffect(() => {
    setData(values);
  }, [values, setData]);

  const getOOSS = async () => {
    const ooss = await ApiService.getAll('ooss');
    setOOSS(ooss.rows);
  };

  const handleSubmit = () => {
    let errors = false;
    const newErrors: RemitosFormErrors = {
      mesPresentacion: false,
      nroOS: false,
    };

    if (!values.mesPresentacion) {
      newErrors.mesPresentacion = true;
      errors = true;
    }

    if (!values.nroOS) {
      newErrors.nroOS = true;
      errors = true;
    }

    if (errors) {
      return;
    }

    onSubmit();
  };

  return (
    <>
      <Card className="bg-secondary shadow">
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">
                <span className='mr-2'>{ title }</span>
                <Loader className={ isLoading ? '': 'hidden' } />
              </h3>
            </Col>
            <Col className="text-right" xs="4">
              <Link to='/admin/remitos'>
                <Button
                  color="primary"
                  size="md"
                >
                  Volver
                </Button>
              </Link>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form>
            <h6 className="heading-small text-muted mb-4">
              Complete los datos
            </h6>
            <div className="pl-lg-4">
              <Row className='mb-2'>
                <Col sm={4}>
                  <FormGroup>
                    <label className="form-control-label">
                      Mes de presentación
                    </label>
                    <DatePicker
                      className={`form-control form-control-alternative ${ errors.mesPresentacion ? '' : '' }`}
                      name='mesPresentacion'
                      onChange={ (val: string) => setValues({ ...values, mesPresentacion: val }) }
                      disabled={ isLoading }
                    />
                  </FormGroup>
                </Col>
                <Col sm={4}>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-last-name">
                      Obra Social
                    </label>
                    <Input
                      className={`form-control-alternative ${ errors.mesPresentacion ? '' : '' }`}
                      type="select"
                      name='nroOS'
                      value={ values.nroOS || 'DEFAULT' }
                      disabled={ isLoading }
                      onChange={ event => setValues({ ...values, nroOS: event.target.value }) }
                    >
                      <option disabled value='DEFAULT' hidden>Seleccione una obra social</option>
                      {
                        ooss.map(item => <option key={item.id} value={item.numero}>{item.codigo}</option>)
                      }
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <Row>
              <div className="update ml-auto mr-auto pt-4">
                <Button
                  className="btn-round"
                  color="success"
                  title='Generar remito'
                  onClick={ () => handleSubmit() }
                  disabled={ isLoading }
                >
                  Procesar
                </Button>
              </div>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}

export default RemitosForm;
