import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardFooter, Table, Container, Row, UncontrolledAlert, Button } from 'reactstrap';
import Pagination from 'components/Pagination';
import { Link, Location, useLocation, useNavigate } from 'react-router-dom';
import { ApiService } from 'services';
import { useUser } from 'context/UserContext';
import { useAlert, useConfirm } from 'hooks';
import AlertModal from 'components/AlertModal';
import ConfirmModal from 'components/ConfirmModal';
import TableLoader from 'components/TableLoader';
import { RawUser } from '@cokiba/types';

function Users() {
  const location = useLocation() as Location;

  const user = useUser();

  const navigate = useNavigate();

  const [data, setData] = useState<RawUser[]>([]);

  // Opciones de paginacion, filtro y orden del listado
  const [currentOptions, setCurrentOptions] = useState({
    sort: {
      field: 'created_at',
      dir: 1,
    },
    page: 1,
    search: '',
  });

  const [isLoading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const [success, setSuccess] = useState(false);
  const [showAlert, message, alert] = useAlert(false, '');
  const [confirm, confirmTitle, confirmAction, setConfirm] = useConfirm(false);

  useEffect(() => {
    fetchData({ page: 1 });
  }, []);

  useEffect(() => {
    if (!location.state) {
      return;
    }

    const { success } = location.state;
    if (success !== undefined) {
      setSuccess(success);

      navigate(location.pathname, {
        replace: true,
      });
    }
  }, [location]);

  const fetchData = async (newOptions = {}) => {
    try {
      // Merge de las nuevas opciones con las viejas
      const options = Object.assign({}, currentOptions, newOptions);

      setLoading(true);

      // Hacemos la petición
      const res = await ApiService.getAll('users', options);

      // Guardamos las opciones
      setCurrentOptions(options);

      // Recuperamos algunos datos de los resultados
      const { rows, count } = res;
      setData(rows);
      setTotal(count);
    } catch (err) {
      console.error(err);
      alert(true, 'Se produjo un error mientras se intentaba recuperar la información de los usuario. ' +
        'Por favor, intente nuevamente más tarde.');
    } finally {
      setLoading(false);
    }
  };

  const changePage = (page: number) => {
    fetchData({
      page,
    });
  };

  const removeUser = async (id: number, confirmed = false) => {
    if (!confirmed) {
      return setConfirm(true, '¿Estás seguro que deseas borrar este usuario?', id);
    }
    setConfirm(false);

    try {
      const res = await ApiService.delete('users', id);
      if (!res || !res.status || res.status !== 'ok') {
        alert(true, 'Se produjo un error mientras se intentaba eliminar el usuario. ' +
          'Por favor, intente nuevamente más tarde.');
        return;
      }

      fetchData();
    } catch (err) {
      console.error(err);
      alert(true, 'Se produjo un error mientras se intentaba eliminar el usuario. ' +
        ' Por favor, intente nuevamente más tarde.');
    }
  };

  return (
    <>
      <AlertModal isOpen={ showAlert } message={ message } onClose={ () => alert(false) } />
      <ConfirmModal
        isOpen={ confirm }
        title={ confirmTitle }
        action={ confirmAction }
        onClose={ () => setConfirm(false) }
        onConfirm={ (id: number) => removeUser(id, true) }
      />
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                {
                  success &&
                  <UncontrolledAlert color='success'>
                    <strong>Éxito!</strong> La operación se realizó correctamente.
                  </UncontrolledAlert>
                }
                <Row className='align-items-center'>
                  <div className='col-8'>
                    <h3 className="mb-0">Lista de usuarios</h3>
                  </div>
                  <div className='col-4 text-right'>
                    <Link to='/admin/usuarios/crear' className="btn-round btn btn-primary btn-md">
                      Nuevo usuario
                    </Link>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>EMail</th>
                    <th>Roles</th>
                    <th>OOSS</th>
                    <th>F. Creación</th>
                    <th>F. Actualización</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    isLoading
                      ? <tr><td colSpan={5} className="text-center"><TableLoader /></td></tr>
                      : data && Array.isArray(data)
                        ? data.map(row => {
                          return (
                            <tr key={row.id}>
                              <td>{ row.email }</td>
                              <td>
                                {
                                  row.roles
                                    ? row.roles.map(r => r.name).join(', ')
                                    : ' - '
                                }
                              </td>
                              <td>
                                {
                                  row.insurer
                                    ? row.insurer.codigo
                                    : ' - '
                                }
                              </td>
                              <td>{ new Date(row.createdAt).toLocaleString('es') }</td>
                              <td>{ new Date(row.updatedAt).toLocaleString('es') }</td>
                              <td>
                                {
                                  user.is(1)
                                    ? <>
                                        <Link
                                          to={`/admin/usuarios/${row.id}/edit`}
                                          className="btn-round btn btn-primary btn-sm"
                                        >
                                          <i className="fa fa-edit"></i>
                                        </Link>
                                        <Button
                                          size='sm'
                                          className='btn-round'
                                          color='warning'
                                          onClick={ () => removeUser(row.id) }
                                        >
                                          <i className="fa fa-trash-alt"></i>
                                        </Button>
                                      </>
                                    : <Link
                                        to={`/admin/usuarios/${row.id}`}
                                        className="btn-round btn btn-primary btn-sm"
                                      >
                                        <i className="fa fa-edit"></i>
                                      </Link>
                                }
                              </td>
                            </tr>
                          );
                        })
                        : null
                  }
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <Pagination page={ currentOptions.page } count={ total } onChangePage={ changePage } limit={ 10 } />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Users;
