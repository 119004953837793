import { useState } from 'react';

type Details = string | number | object;
type CallbackFuncion = () => void;
type SetFunction = (newMessage: string, newDetails?: Details, newCallback?: CallbackFuncion) => void;

type AlertErrorHook = () => [
  boolean,
  string,
  Details,
  CallbackFuncion,
  SetFunction
];

export const useAlertError: AlertErrorHook = () => {
  const [state, setState] = useState(false);
  const [message, setMessage] = useState('');
  const [details, setDetails] = useState<Details>('');
  const [callback, setCallback] = useState<CallbackFuncion>(() => () => setState(false));

  const set: SetFunction = (newMessage: string, newDetails = '', newCallback) => {
    setMessage(newMessage);
    setDetails(newDetails);
    setState(true);

    if (newCallback) {
      setCallback(() => () => {
        setState(false);
        newCallback();
      });
    }
  };

  return [
    state,
    message,
    details,
    callback,
    set,
  ];
};
