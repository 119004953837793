import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Col,
  UncontrolledAlert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { ApiService } from 'services';
import { getQueryString } from 'services/ApiService';
import Table from './Table';
import Filters from './Filters';
import Search from './Search';
import Pagination from '../../components/Pagination';
import { UserOptions, useUser } from 'context/UserContext';
import config from 'config';
import { useAppLocation } from 'hooks/useAppLocation';
import DatePicker from 'components/DatePicker';
import { useAlertPlus } from '../../hooks';
import AlertModal from '../../components/AlertModal';

export default function RequestsList() {
  const navigate = useNavigate();
  const location = useAppLocation();

  const { getDelegation, is, isNot, listOptions, setListOptions } = useUser();
  const [alertShow, alertMessage, alertCallback, alert] = useAlertPlus('');

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExcel, setIsLoadingExcel] = useState(0); // 0 no hace nada, 1 excel, 2 excel detallado
  const [isLoadingAutoAudit, setIsLoadingAutoAudit] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [success, setSuccess] = useState(false);
  const [delegationsFilter, setDelegationsFilter] = useState(false);

  const [autoAuditOpen, setAuditOpen] = useState(false);
  const [autoAuditMonth, setAuditMonth] = useState('');

  const initialOptions = listOptions?.requests ? listOptions.requests : {
    estado_id: is([1, 2, 3]) ? 'any' : '[2, 3]',
    sort: {
      field: 'matricula_profesional_solicitante',
      dir: 1,
    },
    page: 1,
    search: '',
  };

  // Opciones de paginacion, filtro y orden del listado
  const [currentOptions, setCurrentOptions] = useState<UserOptions['requests']>(initialOptions);

  useEffect(() => {
    if (is([1, 3, 4])) {
      setDelegationsFilter(true);
    } else {
      setDelegationsFilter(false);
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (location.state) {
      const { success } = location.state;
      if (success !== undefined) {
        navigate(location.pathname, {
          state: { success },
        });
        setSuccess(success);
      } else {
        setSuccess(false);
      }
    }
  }, [location.state, location.pathname]);

  const fetchData = async (newOptions?: Record<string, unknown>) => {
    // Merge de las nuevas opciones con las viejas
    const options = Object.assign({}, currentOptions, newOptions);

    if (isNot([1, 3, 4])) {
      options.delegation = getDelegation()?.toString();
    }

    setIsLoading(true);

    // Hacemos la petición
    const res = await ApiService.getAll('requests', options);

    // Guardamos las opciones
    setCurrentOptions(options);
    setListOptions({
      ...listOptions,
      requests: options,
    });

    // Recuperamos algunos datos de los resultados
    const { rows, count } = res;
    setData(rows);
    setTotal(count);

    // Terminamos la carga
    setIsLoading(false);
  };

  const downloadExcel = async (detallado = false) => {
    try {
      setIsLoadingExcel(detallado ? 2 : 1);

      const queryString = getQueryString({
        ...currentOptions,
        detallado: detallado ? '1' : undefined,
      });

      const accessToken = localStorage.getItem('accessToken');

      const requestInit: RequestInit = {};
      if (accessToken) {
        requestInit['headers'] = {
          Authorization: accessToken,
        };
      }

      const res = await fetch(`${ config.baseUrl }/requests/excel${ queryString }`, requestInit);

      const blob = await res.blob();

      const file = window.URL.createObjectURL(blob);

      window.location.assign(file);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingExcel(0);
    }
  };

  const autoAudit = async () => {
    try {
      if (!currentOptions?.ooss_id || !autoAuditMonth) {
        return;
      }

      const token = localStorage.getItem('accessToken');
      if (!token) {
        return;
      }

      setIsLoadingAutoAudit(true);

      const response = await fetch(`${ config.baseUrl }/insurers/${ currentOptions.ooss_id }/auto-audit`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ month: autoAuditMonth }),
      });

      if (response.status != 200) {
        alert('Se produjo un error al intentar auditar las solicitudes');
        console.error(response);
        return;
      }

      const data = await response.json();

      if (data.status !== 'ok') {
        alert('Se produjo un error mientras se intentaba realizar la auditoría automática');
      }

      alert(`Se auditaron ${ data.payload } solicitudes`);
      setAuditOpen(false);
    } catch (err) {
      console.error(err);
      alert('Se produjo un error mientras se intentaba realizar la auditoría automática');
    } finally {
      setIsLoadingAutoAudit(false);
    }
  };

  const changePage = (page: number) => {
    fetchData({
      page,
    });
  };

  const changeOrder = (field: string, dir: number) => {
    fetchData({
      sort: {
        field,
        dir,
      },
    });
  };

  const changeFilter = (key: string, value: unknown) => {
    fetchData({
      [key]: value,
    });
  };

  const search = (query: string) => {
    fetchData({
      search: query,
    });
  };

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
          </div>
        </Container>
      </div>
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <Search onSearch={ search } currentQuery={ currentOptions?.search } />
            <Filters
              changeFilter={ changeFilter }
              delegaciones={ delegationsFilter }
              currentOptions={ currentOptions } />
            <Card className="shadow">
              <CardHeader className="border-0">
                {
                  success &&
                  <UncontrolledAlert color="success">
                    <strong>Éxito!</strong> La operación se realizó correctamente.
                  </UncontrolledAlert>
                }
                <Row className="align-items-center">
                  <div className="col-8">
                    <h3 className="mb-0">Lista de solicitudes</h3>
                  </div>
                </Row>
              </CardHeader>

              <Table
                id="requests-table"
                items={ data }
                isLoading={ isLoading }
                changeOrder={ changeOrder }
                currentOrder={ currentOptions?.sort }
              />

              <CardFooter className="py-4">
                <Row>
                  <Col sm={3} className="d-flex justify-content-center d-sm-block mb-4 mb-md-0">
                    <Button color="light" onClick={ () => downloadExcel() } disabled={isLoadingExcel === 2}>
                      {
                        isLoadingExcel === 1
                          ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          : <>Descargar Excel</>
                      }
                    </Button>
                    {
                      currentOptions?.ooss_id == '66'
                        ? <Button
                            color="light"
                            onClick={ () => setAuditOpen(true) }
                          >
                            Auditoría automática
                          </Button>
                        : null
                    }
                  </Col>
                  <Col sm={3} className="d-flex justify-content-center d-sm-block mb-4 mb-md-0">
                    <Button color="light" onClick={ () => downloadExcel(true) } disabled={isLoadingExcel === 1}>
                      {
                        isLoadingExcel === 2
                          ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          : <>Descargar Excel Detallado</>
                      }
                    </Button>
                  </Col>
                  <Col sm={6} className="d-flex justify-content-center d-sm-block">
                    <Pagination
                      page={ currentOptions?.page }
                      count={ total }
                      onChangePage={ changePage }
                      limit={ 10 }
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        isOpen={ autoAuditOpen }
        centered
        toggle={ () => {
          if (!isLoadingAutoAudit) {
            setAuditOpen(false);
          }
        }}
      >
        <ModalHeader>Auditoría Automática</ModalHeader>
        <ModalBody>
          <FormGroup>
            <label className="form-control-label">
              Mes de presentación
            </label>
            <DatePicker
              className="form-control"
              name='mesPresentacion'
              onChange={ (val: string) => setAuditMonth(val) }
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-round"
            color="error"
            size="md"
            onClick={ () => setAuditOpen(false) }
            disabled={ isLoadingAutoAudit }
          >
            Cancelar
          </Button>
          <Button
            className="btn-round"
            color="success"
            onClick={ autoAudit }
            disabled={ isLoadingAutoAudit }
          >
            Aceptar
          </Button>
        </ModalFooter>
      </Modal>
      <AlertModal isOpen={ alertShow } message={ alertMessage } onClose={ alertCallback } />
    </>
  );
}
